export const FETCH_DETAIL_SUCCESS = 'FETCH_DETAIL_SUCCESS'
export const SET_BASIC_INFO_SUCCESS = 'SET_BASIC_INFO_SUCCESS'
export const SET_INFO_SUCCESS = 'SET_INFO_SUCCESS'
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS'
export const FETCH_MAKES_SUCCESS = 'FETCH_MAKES_SUCCESS'
export const FETCH_YEARS_SUCCESS = 'FETCH_YEARS_SUCCESS'
export const FETCH_BADGES_SUCCESS = 'FETCH_BADGES_SUCCESS'
export const FETCH_DETAIL_EXTENDED_SUCCESS = 'FETCH_DETAIL_EXTENDED_SUCCESS'
export const CLEAR_INFO_SUCCESS = 'CLEAR_INFO_SUCCESS'
export const FETCH_INIT_SUCCESS = 'FETCH_INIT_SUCCESS'
export const CLEAR_INFO_EXTEND_SUCCESS = 'CLEAR_INFO_EXTEND_SUCCESS'
export const CLEAR_INFO_MODEL_SUCCESS = 'CLEAR_INFO_MODEL_SUCCESS'
export const CLEAR_INFO_YEAR_SUCCESS = 'CLEAR_INFO_YEAR_SUCCESS'
export const CLEAR_INFO_BODY_TYPES_SUCCESS = 'CLEAR_INFO_BODY_TYPES_SUCCESS'
export const CLEAR_INFO_DRIVE_TYPES_SUCCESS = 'CLEAR_INFO_DRIVE_TYPES_SUCCESS'
export const CLEAR_INFO_TRANS_SUCCESS = 'CLEAR_INFO_TRANS_SUCCESS'
export const SET_PREV_LENGTH_SUCCESS = 'SET_PREV_LENGTH_SUCCESS'
export const SET_SUB_MODEL_LIST_SUCCESS = 'SET_SUB_MODEL_LIST_SUCCESS'
export const SET_REGO_SEARCH_FLAG_SUCCESS = 'SET_REGO_SEARCH_FLAG_SUCCESS'
export const SET_STATE_SUCCESS = 'SET_STATE_SUCCESS'
export const NARROWS_DOWN_RBC = 'NARROWS_DOWN_RBC'
export const CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS = 'CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS'
export const CLEAR_RELEVANT_RBDESC_SUCCESS = 'CLEAR_RELEVANT_RBDESC_SUCCESS'