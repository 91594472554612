import { GET_NZ_VARIABLES, RESET_VARIABLES } from "./actions";
import { GET_NZ_VARIABLES_SUCCESS, RESET_VARIABLES_SUCCESS } from "./mutations";
import NZService from "@/services/nz-service";
import { LP_TYPE, locid } from "@/config"

const initialState = {
  myCarLinks: {
    buildDate: "https://www.autoflip.com.au/find-build-date",
    troubleLogging: "https://www.autoflip.com.au/login-issues",
    guidedCamera: "https://www.autoflip.com.au/guided-camera",
  },
  photoGuideLink: {
    fromLandingPage: "https://www.autoflip.com.au/photo-guide",
    fromMyCar: "https://www.autoflip.com.au/photo-guide",
  },
  policyDocumentsLinks: {
    sellerAgreement: "https://www.autoflip.com.au/seller-agreement",
    successFee: "https://www.autoflip.com.au/seller-fees",
    termsAndConditions: "https://www.autoflip.com.au/terms-and-conditions",
    privacyPolicy: "https://www.autoflip.com.au/privacy-policy",
  },
  contactDetails: {
    phoneNumber: "1300 816 821",
    email: "info@autoflip.com.au",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/autoflipaustralia",
    instagram: "https://www.instagram.com/autoflipau",
    twitter: "https://www.twitter.com/AutoflipAU",
    linkedIn: "https://www.linkedin.com/company/autoflip-australia",
    youtube: "https://www.youtube.com/@autoflip6451",
  },
  sharedMailboxes: {
    reportIssues: "testleadupdates@idomi.com.au",
    unsubscribeEmail: "privacy@autoflip.com.au",
  },
  businessNumber: "ABN: 87 638 825 751",
  autoflipLogo: "https://www.autoflip.com.au",
  homepageLink: "https://nz.ws-staging.idomitech.net",
  footer: {
    footerText: "AutoFlip connects private sellers of cars with our large trusted wholesale buying network, Australia-wide.",
    howItWorks: "https://www.autoflip.com.au/how-it-works",
    forDealers: "https://www.autoflip.com.au/dealers",
    forEnterprise: "https://www.autoflip.com.au/enterprise",
    aboutUs: "https://www.autoflip.com.au/about",
    faq: "https://www.autoflip.com.au/faqs",
    contact: "https://www.autoflip.com.au/contact",
    leaveReviewLink: "https://search.google.com/local/writereview?placeid=ChIJkc4mz8lD1moR5gb_xY6M61I",
    googleReview: "https://www.google.com/search?q=autoflip&rlz=1C1GCEA_enAU1009AU1010&oq=auto&aqs=chrome.1.69i60j69i59l2j69i57j69i60l2j69i65j69i60.2288j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad643c9cf26ce91:0x52eb8c8ec5ff06e6,1,,,",
    googleReviewBadge: "https://embedsocial.com/api/source_image_badges/svg-badge-1-g/en/google/52337",
  },
};

const state = { ...initialState };

const getters = {
  nzVariables(state) {
    return state.nzVariables;
  },
};

const actions = {
  [GET_NZ_VARIABLES](context) {
    return new Promise((resolve, reject) => {
      const params = {
        locid: locid,
        uxeId: LP_TYPE
      }
      NZService.getEnvironmentVariables(params)
        .then(({ data }) => {
          context.commit(GET_NZ_VARIABLES_SUCCESS, data.variablesConfig);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  [RESET_VARIABLES](context) {
    context.commit(RESET_VARIABLES_SUCCESS);
  },
};

const mutations = {
  [GET_NZ_VARIABLES_SUCCESS](state, payload) {
    state.sharedMailboxes = payload.sharedMailboxes;
    state.socialLinks = payload.socialLinks;
    state.contactDetails = payload.contactDetails;
    state.policyDocumentsLinks = payload.policyDocumentsLinks;
    state.photoGuideLink = payload.photoGuideLink;
    state.businessNumber = payload.businessNumber;
    state.myCarLinks = payload.myCarLinks;
    state.autoflipLogo = payload.autoflipLogo;
    state.homepageLink = payload.homepageLink;
    state.footer = payload.footer;
    state.copyBlocks = {
      ...payload.copyBlocks,
    };
  },
  [RESET_VARIABLES_SUCCESS](state) {
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
