import webApi from "./api";

export class NZService {
  async getEnvironmentVariables(params) {
    return webApi.get("ux-journey-config/get-config", { params });
  }
}

const nzService = new NZService();

export default nzService;
