import vehicleInfoService from '@/services/vehicle-info'
import { isEmpty, isNull, mergeWith } from 'lodash-es'
import {
  FETCH_DETAIL,
  SET_BASIC_INFO,
  SET_INFO,
  FETCH_MODELS,
  FETCH_MAKES,
  FETCH_YEARS,
  FETCH_BADGES,
  CLEAR_INFO,
  FETCH_INIT,
  CLEAR_INFO_EXTEND,
  CLEAR_INFO_MODEL,
  CLEAR_INFO_YEAR,
  CLEAR_INFO_BODY_TYPES,
  CLEAR_INFO_DRIVE_TYPES,
  CLEAR_INFO_TRANS,
  SET_PREV_LENGTH,
  SET_REGO_SEARCH_FLAG,
  SET_STATE,
  CLEAR_SERVICE_HISTORY_DETAIL,
  CLEAR_RELEVANT_RBDESC,
} from './actions'
import {
  FETCH_DETAIL_SUCCESS,
  SET_BASIC_INFO_SUCCESS,
  SET_INFO_SUCCESS,
  FETCH_MODELS_SUCCESS,
  FETCH_YEARS_SUCCESS,
  FETCH_MAKES_SUCCESS,
  FETCH_BADGES_SUCCESS,
  FETCH_DETAIL_EXTENDED_SUCCESS,
  CLEAR_INFO_SUCCESS,
  FETCH_INIT_SUCCESS,
  CLEAR_INFO_EXTEND_SUCCESS,
  CLEAR_INFO_MODEL_SUCCESS,
  CLEAR_INFO_YEAR_SUCCESS,
  CLEAR_INFO_BODY_TYPES_SUCCESS,
  CLEAR_INFO_DRIVE_TYPES_SUCCESS,
  CLEAR_INFO_TRANS_SUCCESS,
  SET_PREV_LENGTH_SUCCESS,
  SET_SUB_MODEL_LIST_SUCCESS,
  SET_REGO_SEARCH_FLAG_SUCCESS,
  SET_STATE_SUCCESS,
  CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS,
  NARROWS_DOWN_RBC,
  CLEAR_RELEVANT_RBDESC_SUCCESS,
} from './mutations'

const state = {
  makes: [''],
  models: [''],
  drives: [''],
  transmissions: [''],
  shifts: [''],
  badges: [''],
  editions: [''],
  badgeEditions: [''],
  years: [''],
  bodyTypes: [''],
  colors: [''],
  modelYears: [''],
  curVehicleInfo: {
    make: '',
    model: '',
    badge: '',
    edition: '',
    year: '',
    color: '',
    model_year: '',
    bodyType: '',
    series: '',
    rego: '',
    vin: '',
    drive: '',
    transmission: '',
    shift: '',
    key: '',
    kms: '',
    logBook: false,
    fullServiceHistory: false,
    serviceHistory: '',
    interior: null,
    exterior: null,
    reservePrice: 0,
    customerName: '',
    customerEmail: '',
    customerMobile: '',
    customerPostalCode: '',
    sell: '',
    badgeEdition: '',
    description: '',
    marketingComm: true,
    rbcs: [],
    sellerTermsAndFees: false,
    from: 0,
    to: 0,
    discreteValue: 0,
    warranty: '',
    registrationStatus: '',
    tyreReplacement: '',
  },
  isClearOptions: {
    isClearBadgeEdition: false,
    isClearBody: false,
    isClearDrive: false,
    isClearTrans: false,
  },
  prevLength: {
    prevLengthBadgeEdition: 0,
    prevLengthBody: 0,
    prevLengthDrive: 0,
    prevLengthTrans: 0,
  },
  regoLookupFlag: false,
  serviceHistoryDetail: '',
}

let oldParams = {};

const getters = {
  makes(state) {
    return state.makes
  },
  models(state) {
    return state.models
  },
  drives(state) {
    return state.drives
  },
  badges(state) {
    return state.badges
  },
  badgeEditions(state){
    return state.badgeEditions
  },
  editions(state){
    return state.editions
  },
  transmissions(state) {
    return state.transmissions
  },
  shifts(state) {
    return state.shifts
  },
  colors(state) {
    return state.colors
  },
  years(state) {
    return state.years
  },
  bodyTypes(state) {
    return state.bodyTypes
  },
  curVehicleInfo(state) {
    return {
      ...state.curVehicleInfo,
      make: state.curVehicleInfo.make !== null ? state.curVehicleInfo.make : '',
      model: state.curVehicleInfo.model !== null ? state.curVehicleInfo.model : '',
      badge: state.curVehicleInfo.badge !== null ? state.curVehicleInfo.badge : '',
      drive: state.curVehicleInfo.drive !== null ? state.curVehicleInfo.drive : '',
      transmission: state.curVehicleInfo.transmission !== null ? state.curVehicleInfo.transmission : '',
      shift: state.curVehicleInfo.transmission !== null ? state.curVehicleInfo.transmission : '',
      edition: state.curVehicleInfo.edition !== null ? state.curVehicleInfo.edition : '',
      year: state.curVehicleInfo.year !== null ? state.curVehicleInfo.year : '',
      color: state.curVehicleInfo.color !== null ? state.curVehicleInfo.color : '',
      bodyType: state.curVehicleInfo.bodyType !== null ? state.curVehicleInfo.bodyType : '',
      model_year: state.curVehicleInfo.model_year !== null ? state.curVehicleInfo.model_year : '',
      reservePrice: state.curVehicleInfo.reservePrice !== null && state.curVehicleInfo.reservePrice >= 0 ? state.curVehicleInfo.reservePrice : 0,
      customerName: state.curVehicleInfo.customerName !== null ? state.curVehicleInfo.customerName : '',
      customerEmail: state.curVehicleInfo.customerEmail !== null ? state.curVehicleInfo.customerEmail : '',
      customerMobile: state.curVehicleInfo.customerMobile !== null ? state.curVehicleInfo.customerMobile : '',
      customerPostalCode: state.curVehicleInfo.customerPostalCode !== null ? state.curVehicleInfo.customerPostalCode : '',
      sell: state.curVehicleInfo.sell !== null ? state.curVehicleInfo.sell : '',
      badgeEdition: state.curVehicleInfo.badgeEdition !== null ? state.curVehicleInfo.badgeEdition : '',
      description: state.curVehicleInfo.description !== null ? state.curVehicleInfo.description : '',
      marketingComm: state.curVehicleInfo.marketingComm !== undefined ? state.curVehicleInfo.marketingComm : true,
      sellerTermsAndFees: state.curVehicleInfo.sellerTermsAndFees !== undefined ? state.curVehicleInfo.sellerTermsAndFees : true,
      from: state.curVehicleInfo.from !== null ? state.curVehicleInfo.from : '',
      to: state.curVehicleInfo.to !== null ? state.curVehicleInfo.to : '',
      discreteValue: state.curVehicleInfo.discreteValue !== null ? state.curVehicleInfo.discreteValue : '',
      serviceHistoryDetail: state.curVehicleInfo.serviceHistoryDetail !== null ? state.curVehicleInfo.serviceHistoryDetail : '',
    }
  }
}

const setClear = {
  setClearBody(state) {
    if (state.bodyTypes.length === 1 && state.prevLength.prevLengthBody > state.bodyTypes.length) {
      state.isClearOptions.isClearBody = true;
    } else {
      state.isClearOptions.isClearBody = false;
    }
  },
  setClearDrive(state) {
    if (state.drives.length === 1 && state.prevLength.prevLengthDrive > state.drives.length) {
      state.isClearOptions.isClearDrive = true;
    } else {
      state.isClearOptions.isClearDrive = false;
    }
  },
  setClearTrans(state) {
    if (state.transmissions.length === 1 && state.prevLength.prevLengthTrans > state.transmissions.length) {
      state.isClearOptions.isClearTrans = true;
    } else {
      state.isClearOptions.isClearTrans = false;
    }
  },
  setClearBadgeEdition(state) {
    if (state.badgeEditions.length === 1 && state.prevLength.prevLengthBadgeEdition > state.badgeEditions.length) {
      state.isClearOptions.isClearBadgeEdition = true;
    } else {
      state.isClearOptions.isClearBadgeEdition = false;
    }
  }
}

const setters = {
  setYear(state, payload) {
    state.years = payload.data.years
    if (payload.data.years.length === 1 && payload.data.years[0] !== state.curVehicleInfo.year) {
      state.curVehicleInfo.year = payload.data.years[0];
    } else if (!payload.data.years.includes(Number(state.curVehicleInfo.year))) {
      state.curVehicleInfo.year = '';
    }
  },
  setModel(state, payload) {
    state.models = payload.data.models
    if (payload.data.models.length === 1 && payload.data.models[0] !== state.curVehicleInfo.model) {
      state.curVehicleInfo.model = payload.data.models[0];
    } else if (!payload.data.models.includes(state.curVehicleInfo.model)) {
      state.curVehicleInfo.model = '';
    }
  },
  setBadge(state, payload) {
    if(payload.data.badges.length >= 2) {
      state.badges = [...payload.data.badges, 'Not sure'];
    } else {
      state.badges = payload.data.badges;
    }
    if (payload.data.badges.length === 1 && payload.data.badges[0] !== state.curVehicleInfo.badge) {
      state.curVehicleInfo.badge = payload.data.badges[0];
    } else if (
      !payload.data.badges.includes(state.curVehicleInfo.badge) &&
      state.curVehicleInfo.badge !== "Not sure"
    ) {
      state.curVehicleInfo.badge = "";
    }
  },
  setEdition(state, payload) {
    if(payload.data.editions.length >= 2) {
      state.editions = [...payload.data.editions, 'Not sure'];
    } else {
      state.editions = payload.data.editions;
    }
    if (payload.data.editions.length === 1 && payload.data.editions[0] !== state.curVehicleInfo.edition) {
      state.curVehicleInfo.edition = payload.data.editions[0];
    } else if (
      !payload.data.editions.includes(state.curVehicleInfo.edition) &&
      state.curVehicleInfo.edition !== "Not sure"
    ) {
      state.curVehicleInfo.edition = "";
    }
  },
  setBody(state, payload) {
    state.bodyTypes = payload.data.bodyTypes
    if (payload.data.bodyTypes.length === 1 && payload.data.bodyTypes[0] !== state.curVehicleInfo.bodyType) {
      state.curVehicleInfo.bodyType = payload.data.bodyTypes[0];
    } else if (!payload.data.bodyTypes.includes(state.curVehicleInfo.bodyType)) {
      state.curVehicleInfo.bodyType = '';
    }
    setClear.setClearBody(state);
  },
  setDrive(state, payload) {
    state.drives = payload.data.drives
    if (payload.data.drives.length === 1 && payload.data.drives[0] !== state.curVehicleInfo.drive) {
      state.curVehicleInfo.drive = payload.data.drives[0];
    } else if (!payload.data.drives.includes(state.curVehicleInfo.drive)) {
      state.curVehicleInfo.drive = '';
    }
    setClear.setClearDrive(state);
  },
  setTrans(state, payload) {
    state.transmissions = payload.data.transmissions
    if (payload.data.transmissions.length === 1 && payload.data.transmissions[0] !== state.curVehicleInfo.transmission) {
      state.curVehicleInfo.transmission = payload.data.transmissions[0];
    } else if (!payload.data.transmissions.includes(state.curVehicleInfo.transmission)) {
      state.curVehicleInfo.transmission = '';
    }
    setClear.setClearTrans(state);
  },
  setModelYear(state, payload) {
    const dataList = payload.data.modelYears.filter(item => item != '')
    if(dataList.length >= 2) {
      state.modelYears = [...dataList, 'Not sure'];
    } else {
      state.modelYears = dataList;
    }
    if (dataList.length === 1 && dataList[0] !== state.curVehicleInfo.model_year) {
      state.curVehicleInfo.model_year = dataList[0];
    } else if (
      !dataList.includes(state.curVehicleInfo.model_year) &&
      state.curVehicleInfo.model_year !== "Not sure"
    ) {
      state.curVehicleInfo.model_year = "";
    }
  },
  setBadgeEdition(state, payload) {
    let arr = [];
    payload.data.badges.forEach((badge, index) => {
      if (badge === '') {
        arr.push({
          key: `N/A`,
          value: `N/A|N/A`
        })
      } else {
        arr.push({
          key: `${badge} ${payload.data.editions[index]}`,
          value: `${badge}|${payload.data.editions[index]}`
        })
      }
    });
    state.badgeEditions = arr;
    if(state.badgeEditions.length >= 2) {
      state.badgeEditions = [...state.badgeEditions, { key: "Not sure", value: "Not sure|Not sure"}];
    }
    if (state.badgeEditions.length === 1 && state.badgeEditions[0].value !== state.curVehicleInfo.badgeEdition) {
      state.curVehicleInfo.badgeEdition = state.badgeEditions[0].value;
    } else if (
      !state.badgeEditions.some((item) => item.value === state.curVehicleInfo.badgeEdition) &&
      state.curVehicleInfo.badgeEdition !== "Not sure|Not sure"
    ) {
      state.curVehicleInfo.badgeEdition = "";
    }
    setClear.setClearBadgeEdition(state);
    setClear.setClearBody(state);
    setClear.setClearDrive(state);
    setClear.setClearTrans(state);
  },
}

const actions = {
  [FETCH_INIT](context, params) {
    return new Promise((resolve, reject) => {
      vehicleInfoService.getInfo(params)
        .then(({ data }) => {
          context.commit(NARROWS_DOWN_RBC, data)
          context.commit(FETCH_INIT_SUCCESS, { data, params })
        })
        .then(() => {
          if (context.state.regoLookupFlag) {
            context.commit(SET_SUB_MODEL_LIST_SUCCESS)
          }
        })
        .catch(err => reject(err))
    })
  },
  [FETCH_DETAIL](context, params) {
    if (JSON.stringify(oldParams) !== JSON.stringify(params)) {
      oldParams = {...params};
      return new Promise((resolve, reject) => {
        vehicleInfoService.getInfo(params)
          .then(({ data }) => {
            context.commit(NARROWS_DOWN_RBC, data)
            context.commit(FETCH_DETAIL_SUCCESS, { data, params })
          })
          .catch(err => reject(err))
      })
    }
  },
  [FETCH_MODELS](context, params) {
    return new Promise((resolve, reject) => {
      vehicleInfoService.getInfo(params)
        .then(({ data }) => {
          context.commit(NARROWS_DOWN_RBC, data)
          context.commit(FETCH_MODELS_SUCCESS, { data, params })
          context.commit(FETCH_DETAIL_EXTENDED_SUCCESS, { data, params })
        })
        .catch(err => reject(err))
    })
  },
  [FETCH_MAKES](context) {
    return new Promise((resolve, reject) => {
      vehicleInfoService.getInfo({})
        .then(({ data }) => {
          context.commit(NARROWS_DOWN_RBC, data)
          context.commit(FETCH_MAKES_SUCCESS, { data })
        })
        .catch(err => reject(err))
    })
  },
  [FETCH_YEARS](context, params) {
    return new Promise((resolve, reject) => {
      vehicleInfoService.getInfo(params)
        .then(({ data }) => {
          context.commit(NARROWS_DOWN_RBC, data)
          context.commit(FETCH_YEARS_SUCCESS, { data, params })
          context.commit(FETCH_DETAIL_EXTENDED_SUCCESS, { data, params })
        })
        .catch(err => reject(err))
    })
  },
  [FETCH_BADGES](context, params) {
    return new Promise((resolve, reject) => {
      vehicleInfoService.getInfo(params)
        .then(({ data }) => {
          context.commit(NARROWS_DOWN_RBC, data)
          context.commit(FETCH_BADGES_SUCCESS, { data, params })
          context.commit(FETCH_DETAIL_EXTENDED_SUCCESS, { data, params })
        })
        .catch(err => reject(err))
    })
  },
  [SET_BASIC_INFO](context, data) {
    context.commit(SET_BASIC_INFO_SUCCESS, data)
  },
  [SET_INFO](context, data) {
    context.commit(SET_INFO_SUCCESS, data)
  },
  setBadges(context, data) {
    context.commit('setBadges', data)
  },
  [CLEAR_INFO](context) {
    context.commit(CLEAR_INFO_SUCCESS)
  },
  [CLEAR_INFO_EXTEND](context) {
    context.commit(CLEAR_INFO_EXTEND_SUCCESS)
  },
  [CLEAR_INFO_MODEL](context) {
    context.commit(CLEAR_INFO_MODEL_SUCCESS)
  },
  [CLEAR_INFO_YEAR](context) {
    context.commit(CLEAR_INFO_YEAR_SUCCESS)
  },
  [CLEAR_INFO_BODY_TYPES](context) {
    context.commit(CLEAR_INFO_BODY_TYPES_SUCCESS)
  },
  [CLEAR_INFO_DRIVE_TYPES](context) {
    context.commit(CLEAR_INFO_DRIVE_TYPES_SUCCESS)
  },
  [CLEAR_INFO_TRANS](context) {
    context.commit(CLEAR_INFO_TRANS_SUCCESS)
  },
  [SET_PREV_LENGTH](context, data) {
    context.commit(SET_PREV_LENGTH_SUCCESS, data)
  },
  [SET_REGO_SEARCH_FLAG](context, data) {
    context.commit(SET_REGO_SEARCH_FLAG_SUCCESS, data)
  },
  [SET_STATE](context, data) {
    context.commit(SET_STATE_SUCCESS, data)
  },
  [CLEAR_SERVICE_HISTORY_DETAIL](context) {
    context.commit(CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS)
  },
  [CLEAR_RELEVANT_RBDESC](context) {
    context.commit(CLEAR_RELEVANT_RBDESC_SUCCESS)
  },
}

const mutations = {
  [FETCH_INIT_SUCCESS](state, payload) {
    state.makes = payload.data.makes
    if (payload.data.makes.length === 1) {
      state.curVehicleInfo.make = payload.data.makes[0];
    }
    state.colors = payload.data.colors
    setters.setModel(state, payload)
    setters.setYear(state, payload)
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setBody(state, payload)
    setters.setDrive(state, payload)
    setters.setTrans(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_DETAIL_SUCCESS](state, payload) {
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setBody(state, payload)
    setters.setDrive(state, payload)
    setters.setTrans(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_DETAIL_EXTENDED_SUCCESS](state, payload) {
    setters.setBody(state, payload)
    setters.setDrive(state, payload)
    setters.setTrans(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_MAKES_SUCCESS](state, payload) {
    state.makes = payload.data.makes
    if (payload.data.makes.length === 1) {
      state.curVehicleInfo.make = payload.data.makes[0];
    }
    setters.setModel(state, payload)
    setters.setYear(state, payload)
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_MODELS_SUCCESS](state, payload) {
    setters.setModel(state, payload)
    setters.setYear(state, payload)
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_YEARS_SUCCESS](state, payload) {
    setters.setYear(state, payload)
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setModelYear(state, payload)
  },
  [FETCH_BADGES_SUCCESS](state, payload) {
    setters.setBadge(state, payload)
    setters.setEdition(state, payload)
    setters.setBadgeEdition(state, payload)
    setters.setModelYear(state, payload)
  },
  [SET_BASIC_INFO_SUCCESS](state, data) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      ...data
    }
  },
  [SET_INFO_SUCCESS](state, data) {
    if (isEmpty(data)) {
      state.curVehicleInfo = {
        make: '',
        model: '',
        badge: '',
        edition: '',
        year: '',
        color: '',
        model_year: '',
        bodyType: '',
        series: '',
        rego: '',
        vin: '',
        drive: '',
        transmission: '',
        shift: '',
        key: '',
        kms: '',
        logBook: false,
        fullServiceHistory: false,
        serviceHistory: '',
        interior: null,
        exterior: null,
        reservePrice: 0,
        customerName: '',
        customerEmail: '',
        customerMobile: '',
        customerPostalCode: '',
        sell: '',
        badgeEdition: '',
        description: '',
        marketingComm: true,
        sellerTermsAndFees: false,
        rbcs: [],
        from: 0,
        to: 0,
        discreteValue: 0,
        serviceHistoryDetail: '',
        warranty: '',
        registrationStatus: '',
        tyreReplacement: '',
      }
    } else {
      state.curVehicleInfo = mergeWith({}, state.curVehicleInfo, data, (o, s) => isNull(s) ? o : s)
    }
  },
  setBadges(state, data) {
    state.badges = [...data, 'Not sure'];
  },
  [SET_SUB_MODEL_LIST_SUCCESS](state) {
    setters.setBadge(state, { data: { badges: state.curVehicleInfo.badges } })
    setters.setEdition(state, { data: { editions: state.curVehicleInfo.editions } })
    setters.setBadgeEdition(state, {
      data: {
        badges: state.curVehicleInfo.badges,
        editions: state.curVehicleInfo.editions
      }}
    )
    state.regoLookupFlag = false;
  },
  [SET_REGO_SEARCH_FLAG_SUCCESS](state, data) {
    state.regoLookupFlag = data;
  },
  [CLEAR_INFO_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      make: '',
      model: '',
      badge: '',
      edition: '',
      badgeEdition: '',
      year: '',
      bodyType: '',
      drive: '',
      transmission: '',
      shift: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_EXTEND_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      badge: '',
      edition: '',
      badgeEdition: '',
      bodyType: '',
      drive: '',
      transmission: '',
      shift: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_MODEL_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      model: '',
      year: '',
      badge: '',
      badgeEdition: '',
      edition: '',
      fuelType: '',
      bodyType: '',
      series: '',
      engineType: '',
      drive: '',
      transmission: '',
      shift: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_YEAR_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      year: '',
      badge: '',
      badgeEdition: '',
      edition: '',
      fuelType: '',
      bodyType: '',
      series: '',
      engineType: '',
      drive: '',
      transmission: '',
      shift: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_BODY_TYPES_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      bodyType: '',
      fuelType: '',
      drive: '',
      transmission: '',
      shift: '',
      series: '',
      engineType: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_DRIVE_TYPES_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      drive: '',
      transmission: '',
      shift: '',
      series: '',
      engineType: '',
      model_year: '',
    }
  },
  [CLEAR_INFO_TRANS_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      transmission: '',
      shift: '',
      series: '',
      engineType: '',
      model_year: '',
    }
  },
  [SET_PREV_LENGTH_SUCCESS](state, data) {
    state.prevLength[data.prevLengthName] = state[data.stateName].length
  },
  [SET_STATE_SUCCESS](state, data) {
    state.curVehicleInfo.rego = data.regoValue;
  },
  [NARROWS_DOWN_RBC](state, data) {
    if (!isEmpty(state.curVehicleInfo.rbcs)) {
      const targetRbcs = [];
      state.curVehicleInfo.rbcs.forEach(rbc => {
        if (data.rbcs.includes(rbc)) targetRbcs.push(rbc);
      })
      state.curVehicleInfo = {
        ...state.curVehicleInfo,
        rbcs: targetRbcs,
      }
    }
  },
  [CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS](state) {
    state.curVehicleInfo = {
      ...state.curVehicleInfo,
      serviceHistoryDetail: '',
    }
  },
  [CLEAR_RELEVANT_RBDESC_SUCCESS](state) {
    state.curVehicleInfo.relevantRBDesc = ""
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
