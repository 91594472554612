export const FETCH_DETAIL = 'FETCH_DETAIL'
export const FETCH_MODELS = 'FETCH_MODELS'
export const FETCH_MAKES = 'FETCH_MAKES'
export const FETCH_YEARS = 'FETCH_YEARS'
export const FETCH_BADGES = 'FETCH_BADGES'
export const SET_BASIC_INFO = 'SET_BASIC_INFO'
export const SET_INFO = 'SET_INFO'
export const CLEAR_INFO = 'CLEAR_INFO'
export const FETCH_INIT = 'FETCH_INIT'
export const CLEAR_INFO_EXTEND = 'CLEAR_INFO_EXTEND'
export const CLEAR_INFO_MODEL = 'CLEAR_INFO_MODEL'
export const CLEAR_INFO_YEAR = 'CLEAR_INFO_YEAR'
export const CLEAR_INFO_BODY_TYPES = 'CLEAR_INFO_BODY_TYPES'
export const CLEAR_INFO_DRIVE_TYPES = 'CLEAR_INFO_DRIVE_TYPES'
export const CLEAR_INFO_TRANS = 'CLEAR_INFO_TRANS'
export const SET_PREV_LENGTH = 'SET_PREV_LENGTH'
export const SET_REGO_SEARCH_FLAG = 'SET_REGO_SEARCH_FLAG'
export const SET_STATE = 'SET_STATE'
export const CLEAR_SERVICE_HISTORY_DETAIL = 'CLEAR_SERVICE_HISTORY_DETAIL'
export const CLEAR_RELEVANT_RBDESC = 'CLEAR_RELEVANT_RBDESC'